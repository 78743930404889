import "./OrderModal.css";
import { IOrderFormModal } from "@/widgets/OrderForm/types";
import OrderFormClient from "@/widgets/OrderForm/ui/OrderFormClient";

export const OrderModal: React.FC<IOrderFormModal> = ({ content }) => {
  return (
    <OrderFormClient
      display={"none"}
      selectedOption={content.selectedOption}
      content={content}
    />
  );
};
