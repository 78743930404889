"use client";

import { useEffect, useState } from "react";
import { useScrollDirection } from "@/shared/hooks/useScrollDirection";
import { animationInspector } from "@/shared/lib/scrollAnimations";
import { usePathname } from "next/navigation";

interface IAnimationControls {
  headerStyle: string;
}
const AnimationControls: React.FC<IAnimationControls> = ({ headerStyle }) => {
  let [headerAnimation, setHeaderAnimation] = useState("show");
  let dynamicHeaderStyle = headerStyle;

  const scrollDirection = useScrollDirection();
  const path = usePathname();
  const pageColorTheme =
    path === "/contacts" || path === "/" ? "theme-dark" : "theme-light";

  useEffect(() => {
    if (typeof window === "undefined") return;

    document.documentElement.classList.remove("theme-dark", "theme-light");
    document.documentElement.classList.add(pageColorTheme);

    const header = document.querySelector(".header");
    const onScroll = () => {
      animationInspector();
    };

    switch (scrollDirection) {
      case "down":
        setHeaderAnimation("hide");
        break;
      case "unset":
        setHeaderAnimation("transparent");
        break;
      default:
        setHeaderAnimation("show");
    }

    if (header) {
      header.classList.add(
        `header_${dynamicHeaderStyle}`,
        `${headerAnimation}`,
      );
      window.addEventListener("scroll", onScroll);

      return () => {
        header.classList.remove(
          `header_${dynamicHeaderStyle}`,
          `${headerAnimation}`,
        );
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [scrollDirection, headerAnimation, dynamicHeaderStyle, path]);

  return null;
};

export default AnimationControls;
