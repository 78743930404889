"use client";

import { PricesElement } from "@/widgets/OrderForm/ui/PricesElement/PricesElement";
import { InputTextField } from "../InputTextField/InputTextField";
import { TextareaField } from "../TextareaField/TextareaField";
import { SimpleModal } from "@/features/Modal/SimpleModal";
import { RadioContiner } from "../RadioContiner/RadioContiner";
import { useState } from "react";
import "./FormContainer.css";

import { IFormContainer } from "@/shared/types/IFormContainer";

export const FormContainer: React.FC<IFormContainer> = ({
  formParametrs,
  prices,
  selectedOption,
}) => {
  const [simpleModal, setSimpleModal] = useState(false);
  const { handleSubmit, content, form, sendEmail, register, dynamicContent } =
    formParametrs;

  const nameField = {
    label: content.formData.nameText,
    autoComplete: "name",
    pattern: /^[\p{L}\s\p{M}\-'"]{0,50}$/u,
    cssName: "name",
    minLength: 1,
    maxLength: 20,
    register,
  };

  const emailField = {
    label: content.formData.contactText,
    autoComplete: "email",
    pattern:
      /^([a-zA-Z0-9_\.+\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-\.]+)|((\+?[\d\s\(\)\-]{1,3})?[\d\s\(\)\-]{1,15}$)/,
    cssName: "contact",
    minLength: 7,
    maxLength: 30,
    register,
  };

  const countryField = {
    label: content.formData.countryText,
    autoComplete: "country",
    pattern: /^[\p{L}\s\p{M}\-'"]{0,20}$/u,
    cssName: "country",
    minLength: 2,
    maxLength: 20,
    register,
  };

  const commentField = {
    label: content.formData.commentText,
    autoComplete: "off",
    pattern: /^[\p{L}\s\p{M}\-'"]{0,50}$/u,
    cssName: "comment",
    minLength: 2,
    maxLength: 100,
    register,
  };

  const handleModalState = () => {
    setSimpleModal(!simpleModal);
  };

  const modalProps = {
    modalState: simpleModal,
    type: "terms",
    content: content.userAgreementContent,
    handleModalState,
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit(() => {});
    sendEmail(e);
  };

  return (
    <form
      ref={form}
      onSubmit={handleFormSubmit}
      className={`form__container ${!!prices ? "price__options" : ""}`}
    >
      {!!prices ? (
        <PricesElement
          prices={prices}
          register={register}
          selected={selectedOption}
        />
      ) : (
        ""
      )}
      <InputTextField fieldData={nameField} />
      <InputTextField fieldData={emailField} />
      <InputTextField fieldData={countryField} />
      <TextareaField fieldData={commentField} />
      {/* <input
        className={styles.field__input_surname}
        type="text"
        name="surname"
        minLength="1"
        maxLength="20"
        placeholder="surname"
        pattern="^[\p{L}\s\p{M}'-]{0,50}$"
      /> */}

      <RadioContiner
        title={content.formData.arrayTitle}
        arr={content.formData.array}
        register={register}
      />
      <span className={"form__agreement"}>
        {content.userAgreementText}
        <span
          className={"form__agreementButton"}
          onClick={() => {
            handleModalState();
          }}
        >
          {content.userAgreementButtonText}
        </span>
      </span>
      <div className={"form__submitWrapper"}>
        <div className={"form__submitContainer"}>
          <input
            className={"form__submit"}
            type="submit"
            value={dynamicContent.buttonText}
          />
          <div className={"form__submitArrow"} />
        </div>
      </div>
      <SimpleModal
        modalProps={modalProps}
        handleModalState={handleModalState}
      />
    </form>
  );
};
