"use client";

import { useState } from "react";
import { SimpleModal } from "@/features/Modal/SimpleModal";
import { ITermsAndConditions } from "./interfaces";

const TermsAndConditions: React.FC<ITermsAndConditions> = ({
  title,
  modalContent,
}) => {
  const [simpleModal, setSimpleModal] = useState(false);
  const handleModalState = () => {
    setSimpleModal(!simpleModal);
  };
  const modalProps = {
    modalState: simpleModal,
    type: "terms",
    content: modalContent,
    handleModalState,
  };

  return (
    <>
      <div className={"footer__terms"} onClick={handleModalState}>
        {title}
      </div>
      <SimpleModal modalProps={modalProps} />
    </>
  );
};

export default TermsAndConditions;
