"use client";

import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { FormContainer } from "./FormContainer/FormContainer";
import arrow from "@/shared/images/right-arr.webp";
import emailjs from "@emailjs/browser";
import { Avatars } from "@/shared/ui/Avatars/Avatars";
import "./OrderForm.css";
import { IOrderFormClient } from "../types/index";
import Link from "next/link";

const OrderFormClient: React.FC<IOrderFormClient> = ({
  content,
  display,
  selectedOption,
}) => {
  const form = useRef<HTMLFormElement | null>(null);

  const {
    register,
    handleSubmit,
    formState: {},
  } = useForm();

  const [submitState, setSubmitState] = useState(false);

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (form.current) {
      // sessionStorage.setItem("formSend", true);
      setSubmitState(true);

      emailjs
        .sendForm(
          "service_j3ba6er",
          "template_we8mn3e",
          form.current,
          "oTnq4ufxCtTUQjvqL",
        )
        .then(
          (result) => {},
          (error) => {
            console.error(error.text);
          },
        );
    }
  };
  const dynamicContent = submitState
    ? {
        title: content.submitTitle,
        subtitle: content.submitText,
        buttonText: content.submitLinkText,
      }
    : {
        title: content.title,
        subtitle: content.subtitle,
        buttonText: content.formData.buttonText,
      };

  const formParametrs = {
    handleSubmit,
    content: content,
    form,
    sendEmail,
    register,
    dynamicContent,
    arrow,
  };

  return (
    <section className={"form animate"}>
      <div className={"form__wrapper"}>
        <h2 className={"form__title"}>{dynamicContent.title}</h2>
        <span className={"form__subtitle"}>{dynamicContent.subtitle}</span>
        <Avatars display={display} members={content.formAvatars} />
        {submitState ? (
          <Link
            href="/"
            className={`form__submitWrapper form__submitWrapper_goTo`}
          >
            <div className={"form__submitContainer"}>
              <span className={"form__submit"}>
                {dynamicContent.buttonText}
              </span>
              <div className={"form__submitArrow"} />
            </div>
          </Link>
        ) : (
          <FormContainer
            formParametrs={formParametrs}
            prices={!!content.cards ? content.cards : []}
            selectedOption={selectedOption}
          />
        )}
      </div>
    </section>
  );
};

export default OrderFormClient;
