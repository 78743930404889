import "./TermsModal.css";

export const TermsModal = (props) => {
  const capitalisize = (str) => {
    return str.replace(/(.*?\. )|(.*?\.)/g, (match) => {
      return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
    });
  };
  return (
    <article
      className="terms__text"
      dangerouslySetInnerHTML={{ __html: props.content }}
    />
  );
};
