import { useRef, useState, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { ISliderProps } from "../interfaces/ISliderProps";

export const useCarousel = ({
  content,
  config: {
    scrollStep = 1,
    threshold = 0.85,
    isVisible = true,
    initialIndex = 0, // another scroll method
    centerOffsetX = 0, // another scroll method
    inlinePosition = "start",
  } = {},
}: ISliderProps) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [lastActiveIndex, setLastActiveIndex] = useState<number | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef(
    new Map<number, HTMLDivElement | HTMLAnchorElement>(),
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const observedIndex = [...itemsRef.current.entries()].find(
            ([, node]) => node === (entry.target as HTMLDivElement),
          )?.[0];
        });
      },
      {
        root: rootRef.current,
        rootMargin: "0px",
        threshold,
      },
    );

    if (centerOffsetX) {
      setTimeout(() => scrollToExactPosition(initialIndex, centerOffsetX), 0);
    }
    if (initialIndex) {
      setTimeout(() => handleScrollToIndex(initialIndex), 0);
    }

    itemsRef.current.forEach((node) => {
      observer.observe(node);
    });

    return () => observer.disconnect();
  }, [isVisible, threshold]);

  useEffect(() => {
    if (lastActiveIndex !== null && lastActiveIndex !== currentIndex) {
      const lastElement = itemsRef.current.get(lastActiveIndex);
      if (lastElement) {
        lastElement.classList.remove("active");
      }
    }

    const currentElement = itemsRef.current.get(currentIndex);
    if (currentElement) {
      currentElement.classList.add("active");
    }

    setLastActiveIndex(currentIndex);
  }, [currentIndex]);

  // possibility to add class to all items in viewport
  const handleVisibility = (entry: IntersectionObserverEntry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add("active");
    } else {
      entry.target.classList.remove("active");
    }
  };

  const scrollToExactPosition = (index: number, offsetX: number) => {
    const node = itemsRef.current.get(index);
    const rootNode = rootRef.current;
    if (node && rootNode) {
      const rootRect = rootNode.getBoundingClientRect();
      const itemRect = node.getBoundingClientRect();
      const scrollX =
        rootNode.scrollLeft + itemRect.left - rootRect.left - offsetX;

      rootNode.scrollTo({
        left: scrollX,
        behavior: "smooth",
      });
    }
  };

  const handleScrollToIndex = (index: number) => {
    setCurrentIndex(index);
    if (centerOffsetX) {
      scrollToExactPosition(index, centerOffsetX);
    } else {
      const node = itemsRef.current.get(index);
      if (node) {
        node.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: inlinePosition,
        });
      }
    }
  };

  const handleScrollLeft = () => {
    const newIndex = Math.max(currentIndex - scrollStep, 0);
    handleScrollToIndex(newIndex);
  };

  const handleScrollRight = () => {
    const newIndex = Math.min(currentIndex + scrollStep, content.length - 1);
    handleScrollToIndex(newIndex);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "ArrowLeft") {
      handleScrollLeft();
    } else if (event.key === "ArrowRight") {
      handleScrollRight();
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      debugger;
      // Проверяем, что свайп горизонтальный (движение по X больше, чем по Y)
      if (
        eventData.velocity > 0.3 &&
        Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY) * 1.5
      ) {
        handleScrollRight();
      }
    },
    onSwipedRight: (eventData) => {
      debugger;
      // Проверяем, что свайп горизонтальный (движение по X больше, чем по Y)
      if (
        eventData.velocity > 0.3 &&
        Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY) * 1.5
      ) {
        handleScrollLeft();
      }
    },
    onSwipedUp: () => {
      if (window) {
        window.scrollBy({
          top: 200,
          behavior: "smooth",
        });
      }
    },
    onSwipedDown: () => {
      if (window) {
        window.scrollBy({
          top: -200,
          behavior: "smooth",
        });
      }
    },
    delta: 10,
    trackTouch: true,
    trackMouse: false,
    preventScrollOnSwipe: false, // Разрешаем вертикальную прокрутку
  });

  const handleWheel = (event: React.WheelEvent) => {
    if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
      event.preventDefault();
      if (event.deltaX > 0) {
        handleScrollRight();
      } else {
        handleScrollLeft();
      }
    }
  };

  return {
    rootRef,
    content,
    itemsRef,
    currentIndex,
    swipeHandlers,
    handleScrollLeft,
    handleScrollRight,
    handleKeyPress,
    handleWheel,
  };
};
