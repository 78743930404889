export const animOnScroll = (animItems = []) => {
  if (typeof window === "undefined") return;

  const animStart = 2;

  const offset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
      top: rect.top + scrollTop,
      left: rect.left + scrollLeft,
    };
  };

  const handleScroll = () => {
    animItems.forEach((animItem) => {
      const animItemHeight = animItem.offsetHeight;
      const animItemOffset = offset(animItem).top;

      let animItemPoint = window.innerHeight - animItemHeight / animStart;
      if (animItemHeight > window.innerHeight) {
        animItemPoint = window.innerHeight - window.innerHeight / animStart;
      }

      if (
        window.pageYOffset > animItemOffset - animItemPoint &&
        window.pageYOffset < animItemOffset + animItemHeight
      ) {
        animItem.classList.add("animation");
      } else if (animItem.classList.contains("animate_cyclically")) {
        animItem.classList.remove("animation");
      }
    });
  };

  window.addEventListener("scroll", handleScroll);
  handleScroll();

  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
};

export const animationInspector = () => {
  const animItems = Array.from(document.querySelectorAll(".animate"));

  // animItems.forEach(i => {
  //   i.classList.add('animation')
  // })
  const cleanup = animOnScroll(animItems);

  return cleanup;
};
