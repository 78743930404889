import whiteBurger from "@/shared/images/burger_white.webp";
import blackBurger from "@/shared/images/burger_black.webp";
import styles from "./page.module.css";
import Image from "next/image";
import { IHeaderBurgerProps } from "./interfaces";

// import whiteCross from "@/shared/images/close_white.webp";
// import blackCross from "@/shared/images/close_black.webp";

export const HeaderBurger = ({
  headerStyle,
  handleModalState,
}: IHeaderBurgerProps) => {
  const burger = headerStyle === "light" ? whiteBurger : blackBurger;

  return (
    <Image
      width={20}
      height={20}
      className={styles.header__icon}
      src={burger}
      onClick={() => handleModalState()}
      alt="burger"
    />
  );
};
