"use client";

import { HeaderBurger } from "../HeaderBurger/HeaderBurger";
import { useState } from "react";
import { SimpleModal } from "@/features/Modal/SimpleModal";
import { IHeaderCTABtn } from "./interfaces";
import "./HeaderCTABtn.css";

const HeaderCTABtn = (props: IHeaderCTABtn) => {
  const { bttnText, headerStyle, navbarContent, formData, contactUsButton } =
    props;
  const [modalState, setModalState] = useState(false);

  const handleModalState = () => {
    setModalState(!modalState);
  };

  const modalProps = {
    modalState: modalState,
    type: "navbar",
    content: {
      navbar: navbarContent,
      contactUsButton: contactUsButton,
      formData,
    },
    handleModalState: handleModalState,
  };

  return (
    <>
      <HeaderBurger
        headerStyle={headerStyle}
        handleModalState={handleModalState}
      />
      <button
        className={"header__contact"}
        onClick={handleModalState}
        data-id="open-modal"
      >
        {bttnText}
      </button>
      <SimpleModal modalProps={modalProps} />
    </>
  );
};
export default HeaderCTABtn;
