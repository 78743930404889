import "./RadioContiner.css";
import { IInputTextField } from "./interfaces";

export const RadioContiner: React.FC<IInputTextField> = ({
  title,
  arr,
  register,
}) => {
  return (
    <>
      <span className={"form__chooseType"}>{title}</span>
      <div className={"form__radioContainer"}>
        <label className={"form__radioContainer_1"}>
          <input
            {...register("Тип объекта", { required: true })}
            className={"form__radio"}
            type="radio"
            value={arr[0]}
            // checked
          />
          <span className={"form__radioLabel"}>{arr[0]}</span>
        </label>
        <label className={"form__radioContainer_2"}>
          <input
            {...register("Тип объекта", { required: true })}
            className={"form__radio"}
            type="radio"
            value={arr[1]}
          />
          <span className={"form__radioLabel"}>{arr[1]} </span>
        </label>
      </div>
    </>
  );
};
