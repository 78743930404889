"use client";

import { useEffect, useState } from "react";
import { IGoogleAnalitics } from "./interfaces";

import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import Script from "next/script";
import ruTexts from "../constants/ru.json";
import enTexts from "../constants/en.json";

declare const window: Window & { dataLayer: any };

const updateCookieConsent = () => {
  CookieConsent.showPreferences();
};

const resetCookieConsent = () => {
  CookieConsent.reset(true);
};

const listenForConsent = (state: any, gtagId: string) => {
  window.dataLayer = window.dataLayer || [];
  const pushToDataLayer = (...args: any[]) => window.dataLayer.push(args);
  pushToDataLayer("consent", "default", {
    ad_storage: "denied",
    ad_user_data: "denied",
    ad_personalization: "denied",
    analytics_storage: "denied",
    functionality_storage: "denied",
    personalization_storage: "denied",
    security_storage: "granted",
  });

  state.setLoadScript(true);

  pushToDataLayer("js", new Date());
  pushToDataLayer("config", gtagId);

  const updateGtagConsent = () => {
    pushToDataLayer("consent", "update", {
      ad_storage: CookieConsent.acceptedCategory("advertisement")
        ? "granted"
        : "denied",
      ad_user_data: CookieConsent.acceptedCategory("advertisement")
        ? "granted"
        : "denied",
      ad_personalization: CookieConsent.acceptedCategory("advertisement")
        ? "granted"
        : "denied",
      analytics_storage: CookieConsent.acceptedCategory("analytics")
        ? "granted"
        : "denied",
      functionality_storage: CookieConsent.acceptedCategory("functional")
        ? "granted"
        : "denied",
      personalization_storage: CookieConsent.acceptedCategory("functional")
        ? "granted"
        : "denied",
      security_storage: "granted",
    });
  };

  const handleConsent = () => {
    updateGtagConsent();
  };

  // window.addEventListener("cc:onConsent", () => {
  //   updateGtagConsent();
  //   console.log("Consent given");
  // });

  // window.addEventListener("cc:onChange", () => {
  //   updateGtagConsent();
  //   console.log("Consent changed");
  // });

  window.addEventListener("cc:onConsent", handleConsent);
  window.addEventListener("cc:onChange", handleConsent);

  // Возвращаем функцию очистки для удаления обработчиков событий
  return () => {
    window.removeEventListener("cc:onConsent", handleConsent);
    window.removeEventListener("cc:onChange", handleConsent);
  };
};

const CookieConsentComponent: React.FC<IGoogleAnalitics> = ({ gtagId }) => {
  const [loadScript, setLoadScript] = useState(false);

  useEffect(() => {
    /**
     * All config. options available here:
     * https://cookieconsent.orestbida.com/reference/configuration-reference.html
     */
    const cleanup = listenForConsent({ setLoadScript }, gtagId);

    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: "cloud inline",
          position: "bottom center",
          equalWeightButtons: false,
          flipButtons: false,
        },
      },
      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        // functional: {},
        analytics: {},
        // performance: {},
        // advertisement: {},
      },
      language: {
        default: "ru",
        autoDetect: "browser",
        translations: {
          en: enTexts,
          ru: ruTexts,
        },
      },
    });
    return cleanup; // Вызываем функцию очистки при размонтировании компонента
  }, [gtagId]);

  return (
    <>
      {loadScript && (
        <>
          <Script
            async
            strategy="lazyOnload"
            data-cookiecategory="analytics"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`}
          />
          <Script
            id="google-analytics"
            strategy="lazyOnload"
            data-cookiecategory="analytics"
          >
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
          
              gtag('config', '${gtagId}');
          `}
          </Script>
        </>
      )}
    </>
  );
};

export default CookieConsentComponent;
