"use client";

import "./InputTextField.css";
import { IInputTextField } from "./interfaces";

export const InputTextField: React.FC<IInputTextField> = ({ fieldData }) => {
  return (
    <div className={`field ${fieldData.cssName}`}>
      <input
        className={`field__input`}
        type="text"
        {...fieldData.register(fieldData.cssName)}
        name={fieldData.cssName}
        id={`form__${fieldData.cssName}`}
        minLength={fieldData.minLength}
        maxLength={fieldData.maxLength}
        placeholder="."
        required
        pattern={fieldData.pattern?.source}
        autoComplete="true"
      />
      <label
        htmlFor={`form__${fieldData.cssName}`}
        className={`field__label ${fieldData.cssName}`}
      >
        {fieldData.label}
      </label>
    </div>
  );
};
