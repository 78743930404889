"use client";

import { useState } from "react";
import "./TextareaField.css";
import { ITextareaField } from "./interfaces";

function autosize(e: React.ChangeEvent<HTMLTextAreaElement>) {
  const element = e.target;

  if (typeof window !== "undefined") {
    window.requestAnimationFrame(() => {
      element.style.cssText = "height:0; padding:0";
      element.style.cssText = "height:" + element.scrollHeight + "px";
    });
  }
}

export const TextareaField: React.FC<ITextareaField> = ({ fieldData }) => {
  const [counter, setCounter] = useState(0);

  const characterCount = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setCounter(e.target.value.length);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    autosize(e);
    characterCount(e);
  };

  return (
    <div className={"textarea"}>
      <textarea
        {...fieldData.register(fieldData.cssName)}
        name={fieldData.cssName}
        id={`form__${fieldData.cssName}`}
        minLength={fieldData.minLength}
        maxLength={fieldData.maxLength}
        className={"textarea__input"}
        placeholder="."
        required
        onChange={handleInput}
        rows={1}
      ></textarea>
      <div className={"textarea__count"}>
        <span>{counter}</span>
        <span> / {fieldData.maxLength}</span>
      </div>
      <label
        htmlFor={`form__${fieldData.cssName}`}
        className={`field__label ${fieldData.cssName}`}
      >
        {fieldData.label}
      </label>
    </div>
  );
};
