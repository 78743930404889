"use client";

import { useState } from "react";
import bottomArrow from "@/shared/images/bottom-arrow.webp";
import bottomArrowDark from "@/shared/images/bottom-arrow-dark.webp";
import styles from "./page.module.css";
import "./HeaderLanguageSelector.css";
import Image from "next/image";
import Link from "next/link";
import { IHeaderLanguageSelector } from "./interfaces";
import { localeOptions } from "@/shared/constants/constants";

const HeaderLanguageSelector: React.FC<IHeaderLanguageSelector> = ({
  headerStyle,
  locale,
}) => {
  const activeLocale = locale;
  const [isActive, setIsActive] = useState(false);
  const bottomIcon = headerStyle === "light" ? bottomArrow : bottomArrowDark;
  const handleLangSwitch = () => setIsActive(!isActive);

  return (
    <div className={styles.headerLang} onMouseLeave={() => setIsActive(false)}>
      <div
        className={styles.headerLang__itemContainer}
        onClick={handleLangSwitch}
      >
        <span className={styles.headerLang__item}>{locale}</span>
        <Image
          className={`headerLang__bottomArrow ${isActive ? "active" : ""}`}
          src={bottomIcon}
          alt="bottom arrow"
          width={11}
          height={7}
        />
      </div>
      <ul className={`headerLang__options ${isActive ? "active" : ""}`}>
        {localeOptions.map((locale, index) =>
          activeLocale === locale.lang ? (
            ""
          ) : (
            <Link href={locale.link} key={index}>
              <li className={styles.headerLang__optionsItem}>{locale.lang}</li>
            </Link>
          ),
        )}
      </ul>
    </div>
  );
};

export default HeaderLanguageSelector;
