export const pinchZoom = (imageElement) => {
  let imageElementScale = 1;
  let start = {};

  // Calculate distance between two fingers
  const distance = (event) => {
    return Math.hypot(
      event.touches[0].pageX - event.touches[1].pageX,
      event.touches[0].pageY - event.touches[1].pageY,
    );
  };

  imageElement.addEventListener("touchstart", (event) => {
    if (event.touches.length === 2) {
      event.preventDefault(); // Prevent page scroll

      // Calculate where the fingers have started on the X and Y axis
      start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
      start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
      start.distance = distance(event);
    }
  });

  imageElement.addEventListener("touchmove", (event) => {
    if (event.touches.length === 2) {
      event.preventDefault(); // Prevent page scroll
      let scale;

      // Safari provides event.scale as two fingers move on the screen
      // For other browsers just calculate the scale manually
      if (event.scale) {
        scale = event.scale;
      } else {
        const deltaDistance = distance(event);
        scale = deltaDistance / start.distance;
      }

      imageElementScale = Math.min(Math.max(1, scale), 4);

      // Calculate how much the fingers have moved on the X and Y axis
      const deltaX =
        ((event.touches[0].pageX + event.touches[1].pageX) / 2 - start.x) * 2; // x2 for accelarated movement
      const deltaY =
        ((event.touches[0].pageY + event.touches[1].pageY) / 2 - start.y) * 2; // x2 for accelarated movement

      // Transform the image to make it grow and move with fingers
      const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`;
      imageElement.style.transform = transform;
      imageElement.style.WebkitTransform = transform;

      // containerization

      // let imageRect = imageElement.getBoundingClientRect();
      // let containerRect = imageElement.parentElement.getBoundingClientRect();
      // let correctedCords = { x: deltaX, y: deltaY };
      // if (imageRect.left > containerRect.left)
      //   correctedCords.x -= imageRect.left - containerRect.left;
      // if (imageRect.right < containerRect.right)
      //   correctedCords.x -= imageRect.right - containerRect.right;
      // if (imageRect.top > containerRect.top)
      //   correctedCords.y -= imageRect.top - containerRect.top;
      // if (imageRect.bottom < containerRect.bottom)
      //   correctedCords.y -= imageRect.bottom - containerRect.bottom;
      // imageElement.style.transform = `translate3d(${correctedCords.x}px, ${correctedCords.y}px, 0) scale(${imageElementScale})`;

      // containerization end

      imageElement.style.zIndex = "9999";
    }
  });

  imageElement.addEventListener("touchend", (event) => {
    imageElement.style.transform = "";
    imageElement.style.WebkitTransform = "";
    imageElement.style.zIndex = "";
    imageElement.style.transition = "all .5s";
  });
};

// =====================================================================================

// export const pinchZoom = (imageElement) => {
//   let imageElementScale = 1;
//   console.log("da da da");
//   let start = {};

//   // Calculate distance between two fingers
//   const distance = (event) => {
//     return Math.hypot(
//       event.touches[0].pageX - event.touches[1].pageX,
//       event.touches[0].pageY - event.touches[1].pageY
//     );
//   };

//   imageElement.addEventListener("touchstart", (event) => {
//     console.log("touchstart", event);
//     if (event.touches.length === 2) {
//       event.preventDefault(); // Prevent page scroll

//       // Calculate where the fingers have started on the X and Y axis
//       start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
//       start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
//       start.distance = distance(event);
//     }
//   });

//   imageElement.addEventListener("touchmove", (event) => {
//     console.log("touchmove", event);
//     if (event.touches.length === 2) {
//       event.preventDefault(); // Prevent page scroll
//       let scale;

//       // Safari provides event.scale as two fingers move on the screen
//       // For other browsers just calculate the scale manually
//       if (event.scale) {
//         scale = event.scale;
//       } else {
//         const deltaDistance = distance(event);
//         scale = deltaDistance / start.distance;
//       }

//       imageElementScale = Math.min(Math.max(1, scale), 4);

//       // Calculate how much the fingers have moved on the X and Y axis
//       let deltaX =
//         ((event.touches[0].pageX + event.touches[1].pageX) / 2 - start.x) * 2; // x2 for accelerated movement
//       let deltaY =
//         ((event.touches[0].pageY + event.touches[1].pageY) / 2 - start.y) * 2; // x2 for accelerated movement

//       // Apply transformation
//       const transform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`;
//       imageElement.style.transform = transform;
//       imageElement.style.WebkitTransform = transform;

//       // Constrain within container
//       let imageRect = imageElement.getBoundingClientRect();
//       let containerRect = imageElement.parentElement.getBoundingClientRect();

//       // Correct X axis position
//       if (imageRect.left > containerRect.left) {
//         deltaX -= (imageRect.left - containerRect.left) / imageElementScale;
//       }
//       if (imageRect.right < containerRect.right) {
//         deltaX -= (imageRect.right - containerRect.right) / imageElementScale;
//       }

//       // Correct Y axis position
//       if (imageRect.top > containerRect.top) {
//         deltaY -= (imageRect.top - containerRect.top) / imageElementScale;
//       }
//       if (imageRect.bottom < containerRect.bottom) {
//         deltaY -= (imageRect.bottom - containerRect.bottom) / imageElementScale;
//       }

//       // Apply corrected transformation
//       const correctedTransform = `translate3d(${deltaX}px, ${deltaY}px, 0) scale(${imageElementScale})`;
//       imageElement.style.transform = correctedTransform;
//       imageElement.style.WebkitTransform = correctedTransform;

//       imageElement.style.zIndex = "9999";
//     }
//   });

//   imageElement.addEventListener("touchend", (event) => {
//     console.log("touchend", event);
//     // Reset image to its original format
//     imageElement.style.transform = "";
//     imageElement.style.WebkitTransform = "";
//     imageElement.style.zIndex = "";
//     imageElement.style.transition = "all .5s";
//   });
// };

// =====================================================================================

// export const pinchZoom = (imageElement) => {
//   let imageElementScale = 1;
//   let start = {};
//   const containerWidth = imageElement.parentElement.clientWidth;
//   const containerHeight = imageElement.parentElement.clientHeight;

//   // Calculate distance between two fingers
//   const distance = (event) => {
//     return Math.hypot(
//       event.touches[0].pageX - event.touches[1].pageX,
//       event.touches[0].pageY - event.touches[1].pageY
//     );
//   };

//   imageElement.addEventListener("touchstart", (event) => {
//     if (event.touches.length === 2) {
//       event.preventDefault();
//       start.x = (event.touches[0].pageX + event.touches[1].pageX) / 2;
//       start.y = (event.touches[0].pageY + event.touches[1].pageY) / 2;
//       start.distance = distance(event);
//     }
//   });

//   imageElement.addEventListener("touchmove", (event) => {
//     if (event.touches.length === 2) {
//       event.preventDefault();
//       let scale;

//       if (event.scale) {
//         scale = event.scale;
//       } else {
//         const deltaDistance = distance(event);
//         scale = deltaDistance / start.distance;
//       }

//       // Limit scale based on container size and image original size (assuming you have access to original image width/height)
//       const maxScale = Math.min(
//         containerWidth / imageElement.naturalWidth,
//         containerHeight / imageElement.naturalHeight,
//         4 // Set a maximum zoom level if needed
//       );
//       imageElementScale = Math.min(Math.max(1, scale), maxScale);

//       const deltaX =
//         ((event.touches[0].pageX + event.touches[1].pageX) / 2 - start.x) * 2;
//       const deltaY =
//         ((event.touches[0].pageY + event.touches[1].pageY) / 2 - start.y) * 2;

//       // Ensure translation stays within container bounds
//       const correctedX = Math.max(
//         0,
//         Math.min(deltaX, containerWidth - imageElement.clientWidth * imageElementScale)
//       );
//       const correctedY = Math.max(
//         0,
//         Math.min(deltaY, containerHeight - imageElement.clientHeight * imageElementScale)
//       );

//       const transform = `translate3d(${correctedX}px, ${correctedY}px, 0) scale(${imageElementScale})`;
//       imageElement.style.transform = transform;
//       imageElement.style.WebkitTransform = transform;
//       imageElement.style.zIndex = "9999";
//     }
//   });

//   imageElement.addEventListener("touchend", (event) => {
//     imageElement.style.transform = "";
//     imageElement.style.WebkitTransform = "";
//     imageElement.style.zIndex = "";
//   });
// };
