"use client";

import Link from "next/link";
import "./Navbar.css";
import { usePathname } from "next/navigation";
import { INavbarProps } from "./interfaces";

export const Navbar: React.FC<INavbarProps> = ({ navbar }) => {
  const pathname = usePathname();
  const tgLinkNewPage = (i: string) =>
    i === "https://t.me/blwall_brief_bot" ? "blank" : "";

  return (
    <ul className={"header__navbar"}>
      {navbar.map((i) =>
        i.link === pathname ? (
          <h5 className={"header__navbarItem current"} key={i.id}>
            {i.title}
          </h5>
        ) : (
          <Link
            href={i.link}
            className={"header__navbarItem"}
            key={i.id}
            target={tgLinkNewPage(i.link)}
          >
            {i.title}
          </Link>
        ),
      )}
    </ul>
  );
};
